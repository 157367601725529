import "../styles/Home.css";
import resume from "../assets/resume.pdf";
import homeVideo from "../assets/home-video.mp4";

const Home = () => {
  return (
    <div className="home" id="home">
      <div className="home__content">
        <h1>
          <br />
          I'm Damon <b>Green</b>
          halgh
        </h1>
        <h3>
          I <b>make</b> things
        </h3>
        <div className="home__button-container">
          <a
            href={resume}
            className="home__button home__button--alt"
            target="_blank"
            rel="noreferrer"
            download
          >
            Resume
          </a>
          <button
            onClick={() => window.scrollTo(0, window.innerHeight)}
            title="Show More"
            className="home__button"
          >
            Show me!
          </button>
        </div>
      </div>
      <video loop playsInline autoPlay muted className="home__video">
        <source src={homeVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default Home;
