import "../styles/App.css";
import Console from "../components/Console";
import Home from "../components/Home";
import About from "../components/About";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Art from "../components/Art";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Home />
      <About />
      <div className="banner">
        ???.ADD.THE.GREEN.TO.GREENHALGH.NEVER.ADD.THE.GREEN.TO.GREENHALGH.ALWAYS.ADD.THE.GREEN.TO.GREENHALGH.???.ADD.THE.GREEN.TO.GREENHALGH.NEVER.ADD.THE.GREEN.TO.GREENHALGH.ALWAYS.ADD.THE.GREEN.TO.GREENHALGH.
      </div>
      <Projects />
      <Art />
      <Contact />
      <Footer />
      <Console />
    </div>
  );
}

export default App;
