import "../styles/Contact.css";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="contact-container">
        <form
          className="contact__form"
          action="https://public.herotofu.com/v1/5adb50a0-91ea-11ec-8462-6960be7ce578"
          method="post"
        >
          <h2 style={{ fontSize: "32px", marginBottom: "16px" }}>
            Let's Talk!
          </h2>
          <p>
            Got an <b>interesting</b> idea? <b>Feedback</b> on this site, or
            projects I've worked on? <b>Send me a message!</b>
          </p>
          <div className="contact__row" style={{ marginTop: "16px" }}>
            <input
              className="contact__input"
              name="Name"
              id="name"
              type="text"
              placeholder="Name"
              required
            />
            <input
              className="contact__input"
              name="Email"
              id="email"
              type="email"
              placeholder="Email"
              required
            />
          </div>
          <textarea
            cols="30"
            rows="5"
            className="contact__input contact__message"
            name="Message"
            id="message"
            type="text"
            placeholder="Message"
            required
          />
          <input
            className="contact__button interactable"
            type="submit"
            value="Submit"
            style={{ marginTop: "32px" }}
          />
        </form>
      </div>
    </div>
  );
};

export default Contact;
