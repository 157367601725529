import "../styles/About.css";
import profile from "../assets/profile.png";

const About = () => {
  const linkBase = "https://cdn.jsdelivr.net/gh/devicons/devicon/icons";
  return (
    <div className="about" id="about">
      <div className="about-container">
        <div
          className="section__impact-text"
          style={{ top: "128px", left: "256px", color: "rgb(236, 241, 245)" }}
        >
          01
          <br />
          ME
        </div>
        <div className="about__content-container">
          <div className="about__header-container">
            <h2 className="about__header-text">"Hello, World!"</h2>
            <div className="waiting-pointer waiting-pointer--about" />
          </div>
          <p>
            Hi there! My name is <b>Damon Greenhalgh</b>. I'm a{" "}
            <b>Software Engineer</b> based in Auckland, New Zealand. I am
            currently working at Datacom Data Centres as an Associate Engineer
            on the Apps team. I completed my Bachelor of Science (Bsc) majoring
            in Computer Science and Mathematics at the University of Auckland.
            <br />I have passions across many fields including mathematics,
            design, illustration and music.
          </p>
        </div>
        <img src={profile} className="about__portrait" alt="Portrait" />
      </div>
    </div>
  );
};

export default About;
