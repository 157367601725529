import "../styles/Navbar.css";
import { Link } from "react-router-dom";
import brandIcon from "../assets/brand.png";

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        {/* <img src={brandIcon} style={{ width: "32px", height: "32px" }} /> */}
      </Link>
    </nav>
  );
};

export default Navbar;
