import "../styles/Art.css";
import sketch from "../assets/art-bg.png";
import { a0, a1, a2, a3, a4, a5, a6, a7, a8, a9 } from "../assets/art";
import { useState } from "react";
import artData from "../data/art.json";
import { FaExpand } from "react-icons/fa";

const illustrations = [a0, a1, a2, a3, a4, a5, a6, a7, a8, a9];

const Art = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <div className="art">
      <img
        src={sketch}
        style={{
          position: "absolute",
          left: "0",
          height: "200%",
          top: "0",
          opacity: "0.1",
          zIndex: "2",
        }}
      />
      <div className="art-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            width: "min-content",
          }}
        >
          <div
            className="section__impact-text"
            style={{
              top: "0",
              left: "0px",
              color: "#dcdddf",
            }}
          >
            03
            <br />
            ART
          </div>
          <p>
            One my hobbies is <b>Digital Illustration</b>. Below are a
            collection of some the peices I am proud of.
          </p>
          <div className="gallery">
            {illustrations.map((item, index) => (
              <div
                key={index}
                className={
                  "gallery__item-frame" +
                  (selectedIndex === index
                    ? " gallery__item-frame--selected"
                    : "")
                }
                onClick={() =>
                  setSelectedIndex(selectedIndex === index ? -1 : index)
                }
              >
                <img src={item} className="gallery__item" />
                <div className="gallery__item-number">{index}</div>
              </div>
            ))}
          </div>
        </div>
        {selectedIndex !== -1 && (
          <div className="showcase">
            <img
              src={illustrations[selectedIndex]}
              className="showcase__item"
            />
            <div className="showcase__type-container">
              {artData[selectedIndex].type.map((type, index) => (
                <p className="showcase__type" key={index}>
                  {type}
                </p>
              ))}
              <a
                href={illustrations[selectedIndex]}
                target="_blank"
                style={{ marginLeft: "auto" }}
              >
                <FaExpand size={"1.25em"} />
              </a>
            </div>
            <h3>{artData[selectedIndex].title}</h3>
            <p>{artData[selectedIndex].description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Art;
